.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    width: 50px;
    height: 50px;
    font-size: 54px;
    background-color: oldlace;
    border-radius: 50%;
    opacity: 0.8;
}
.owl-theme .owl-nav{
    text-align: unset;
}
.owl-carousel .owl-nav button.owl-prev{
    position: absolute;
    left: 10px;
    top:121px
}
.owl-carousel .owl-nav button.owl-next{
    position: absolute;
    right:10px;
    top:121px

}
.owl-carousel .owl-nav button.owl-prev span,.owl-carousel .owl-nav button.owl-next span{
    display: block;
    margin-top: -20px;
}


.team-member-card {
    .overlay{
      position: absolute;
      top: 0;
      left: 0;
      
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  
    &:hover .overlay {
      opacity: 1;
      
    }
  }
  
  /* Add this CSS to your stylesheets or in your component if using styles-in-JS */

.enlarged-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Adjust the z-index as needed */
  }
  
  .enlarged-image-container {
    max-width: 80%;
    max-height: 80%;
    position: relative;
  }
  
  .enlarged-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Adjust as needed to maintain aspect ratio */
  }
  .close-icon {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 60px;
    color: #E7452C;
    cursor: pointer;
  }


/* Custom CSS for Navbar */
@media (max-width: 991.98px) {
    #hide{
      display: block !important;
    }

}
@media (min-width: 992px) {
    #hide{
      display: none !important;
    }
  .a .li{
    text-align: justify;
  }

}
ul.a {
  list-style-type: circle;
}